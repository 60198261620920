.auth-bg-image {
    background-position: center !important;
    background-size: cover !important;
}

.form-group {
    position: relative !important;

    .img-icon {
        position: absolute !important;
    }

    .form-control + .img-icon {
        top: calc(100% - 40%);
        bottom: auto;
        left: auto;
        right: 20px !important;
        cursor: pointer;
        z-index: 1;
    }
}

.login-btn {
    border-radius: 8px !important;
}

.or-divider {
    position: relative !important;
    padding-top: 5px;
    padding-bottom: 5px;
    .or-text {
        position: absolute !important;
        background-color: var(--bp-white) !important;
        padding: 10px !important;
        top: -10px;
        bottom: 0;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        left: calc(100% - 55%) !important;
    }
}

.login_with-google {
    @include Montserrat;
    font-weight: 400 !important;
    border-radius: 8px !important;
    background-color: var(--bp-white-blue) !important;
    border: none !important;
}

.bk-reg-continue {
    font-size: 22px;
}
