.fix-chat-card-navigation {
    position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;

    .card-body {
        overflow: hidden !important;
        overflow-y: scroll !important;
        @include styledScrollbar;
    }
}
