/**
 * @company: Beekle
 * @version: 1.0
 * @contractor: Mortalweb
 * @license: OTI-SOFT
 */

/* colors and vars */
@import "partials/_colors";
@import "partials/_vars";
@import "partials/_override";
@import "partials/_selectors";
@import "partials/_mquery";

//  components
@import "components/_logos";
@import "components/_app-navbar";
@import "components/_homepage";
@import "components/_auth-pages";
@import "components/_about-us-page";
@import "components/_contact-us-page";
@import "components/_faq-page";
@import "components/_dashboard-pages";
@import "components/_spot-wallet-page";
@import "components/_onboarding";
@import "components/_marketplace";
@import "components/_trade-coins";
@import "components/_chats";
@import "components/_profile-pages";
@import "components/_verification";
@import "components/_components";
@import "components/_fixChatNavigation";
/* AQ implementations */
@import "components/_qa001";

.app_footer-sm {
    padding: 10px !important;
    background-color: var(--bp-white) !important;
    border: none !important;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .copyright {
        width: 100% !important;
        text-align: center !important;
        padding: 5px !important;
    }
}

a {
    text-decoration: none;
}

body {
    @include styledScrollbar;
    @include Montserrat;
    overflow-x: hidden !important;
    min-height: 100vh !important;
    display: flex !important;
    flex-direction: column !important;
}
