@media screen and (max-width: 576px) {
    .bp_header {
        .bp-desktop-navbar {
            display: none !important;
        }
        .bp-mob-navbar,
        .bp_mob-nav-content,
        .bp_mob-nav-container {
            display: block !important;
        }
    }

    .bp_getstarted-btn {
        padding: 5px !important;
        font-size: 22px !important;
    }

    .bp_home-section-002-row {
        display: none;
    }

    .bp_home-section-002-slider {
        display: block !important;
    }
}

.testimony-row {
    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.testimony-slider {
    @media screen and (min-width: 769px) {
        display: none !important;
    }
}

.dash-banner {
    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.close-dash-nav {
    transition: all 0.5s !important;
    @media screen and (min-width: 768px) {
        display: none !important;
    }

    padding-block: 10px;
    height: 72px;
    position: relative !important;
    .btn-close {
        position: absolute !important;
        right: 15px;
        font-size: 22px;
        border-radius: 100%;
        padding: 10px;
    }
}

.dash-nav-header {
    @media screen and (max-width: 767px) {
        display: none !important;
    }
}

.dash-sidebar-col {
    transition: left 0.5s !important;
    @media screen and (max-width: 768px) {
        position: fixed !important;
        width: 100%;
        height: 100%;
        top: 0 !important;
        bottom: 0 !important;
        z-index: 100;
        background-color: var(--bp-white) !important;

        &.show {
            left: 0% !important;
            right: 0% !important;
        }

        &.hide {
            left: auto !important;
            right: -250% !important;
        }
    }
}

.dash-mob-navbar {
    display: none !important;
    @media screen and (max-width: 768px) {
        display: block !important;
        background-color: var(--bp-white) !important;
        padding: 10px;

        .nav-items {
            display: flex !important;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.4rem !important;
        }

        .mob-logo {
            width: 150px !important;
        }

        .mob-user-avatar {
            width: 35px;
            height: 35px;
        }

        .notify-badge-container {
            position: relative !important;
        }
        .notify-badge {
            display: block;
            position: absolute !important;
            padding: 4px;
            top: 0px;
            right: 10px;
            font-size: 9px !important;
            text-align: center !important;
        }
        .mob-nav-icon {
            font-size: 32px;
            cursor: pointer;
            margin-right: 0.5rem;
        }

        .navtabs {
            border: 0 !important;
            border-radius: 10 !important;
            background: rgba(132, 124, 124, 0.06) !important;
            height: 50px !important;
            justify-content: flex-start !important;
            align-items: center !important;
            .nav-item,
            .nav-link {
                margin: 0 !important;
                color: rgba(28, 78, 152, 1) !important;
                border: 0 !important;
                border-radius: 0 !important;
                padding: 0px !important;
                font-size: 12px !important;
                font-weight: 700 !important;
            }

            .nav-item {
                &:not(:last-child) {
                    margin-right: 0.3rem !important;
                }
            }
        }

        .search-icon {
            font-size: 22px !important;
        }

        & ~ .bp-mob-navbar,
        & ~ .bp-desktop-navbar {
            display: none !important;
        }
    }
}

.dash-search-form-overlay {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    right: 0 !important;
    bottom: auto;
    top: -250%;
    z-index: 10320000000000;
    background-color: var(--bp-blue-overlay) !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
    @include styledScrollbar;
    transition: top 0.5s !important;
    &.show {
        bottom: 0% !important;
        top: 0% !important;
    }

    .search-input {
        .btn {
            border-radius: 0 !important;
            border-top-right-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
        }

        .form-control {
            border-top-left-radius: 50px !important;
            border-bottom-left-radius: 50px !important;
        }
    }

    form {
        margin-bottom: 1.5rem !important;
        border-bottom: 2px solid #ddd;
    }

    .search-result-item {
        margin: 0.5rem !important;
    }
}

.user-mob-drop-opts {
    display: none !important;

    @media screen and (max-width: 768px) {
        display: block !important;
        position: fixed !important;
        top: 0 !important;
        bottom: 0 !important;
        left: -250%;
        right: auto;
        transition: left 0.5s !important;
        z-index: 1030;
        background-color: var(--bp-white) !important;

        &.show {
            left: 0 !important;
            right: 0 !important;
        }

        .user-header {
            background-color: rgba(228, 237, 246, 0.4) !important;
            padding: 10px;

            .header-group {
                display: flex !important;
                flex-direction: row !important;
                justify-content: space-between !important;
                align-items: center !important;
            }

            .user-levels {
                color: rgba(179, 183, 10, 1) !important;
            }

            .close-user-dropdown-opts {
                margin: 0.5rem !important;
                font-size: 22px !important;
            }
        }

        .nav-group {
            .list-group-item {
                padding-top: 20px;
                padding-bottom: 20px;

                .nav-icon {
                    font-size: 22px;
                }
            }
        }
    }
}

.app-mob-menu {
    display: none !important;

    @media screen and (max-width: 768px) {
        display: block !important;
        position: fixed !important;
        top: 0 !important;
        bottom: 0 !important;
        left: auto;
        right: -250%;
        height: 100%;
        transition: right 0.5s !important;
        z-index: 1030;
        background-color: var(--bp-white) !important;
        overflow: hidden !important;
        overflow-y: scroll !important;
        @include styledScrollbar;

        &.show {
            left: 0 !important;
            right: 0 !important;
        }

        .user-header {
            background-color: rgba(228, 237, 246, 0.4) !important;
            padding: 10px;

            .header-group {
                display: flex !important;
                flex-direction: row !important;
                justify-content: flex-end !important;
                align-items: center !important;
            }

            .user-levels {
                color: rgba(179, 183, 10, 1) !important;
            }

            .close-menu {
                margin: 0.5rem !important;
                font-size: 22px !important;
            }
        }

        .nav-group {
            .list-group-item {
                padding-top: 20px;
                padding-bottom: 20px;
                border: 0 !important;

                .nav-icon {
                    font-size: 22px;
                }
            }
        }
    }
}

.dash-desktop-content {
    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.dash-mobile-content {
    @media screen and (min-width: 769px) {
        display: none !important;
    }
}
