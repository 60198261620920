.trade-form {
    position: relative !important;

    .traders-chat-toggler {
        position: absolute !important;
        z-index: 1030;
        right: 5%;
        top: -10%;
        width: 75px;
        height: 50px;
        font-size: 18px;

        &::before {
            content: "";
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
            position: absolute;
            top: 90%;
            right: 25%;
            width: calc(100% - 95%);
            z-index: 1030;
            transform: rotate(10deg);
        }

        &.bg-blue {
            &::before {
                border-top-color: var(--bp-text-blue) !important;
            }
        }
    }
}
