.verif-card {
    border: 1px solid var(--bp-black) !important;
    @include shadow;

    .level-h {
        color: #74af04;
    }
}

.verif-card + .verif-card {
    margin-top: 1.5rem;
}

.verif-item-icon {
    font-size: 32px;
    &.allowed {
        color: #01a601;
    }

    &.not-allowed {
        color: #eb0100;
    }
}
