.form-control,
.form-select {
    border-radius: 11.562px !important;
    // border: 0.963504px solid #000000;
    @include shadow-sm;
    &::placeholder {
        color: var(--bp-selection) !important;
        font-family: "Roboto";
        font-weight: 400;
    }

    &:active,
    &:focus {
        border: 0.963504px solid #000000 !important;
    }
}

.form-group + .form-group {
    margin-top: 1.5rem !important;
}

.input-group {
    .form-control {
        border-top-left-radius: 17px !important;
        border-bottom-left-radius: 17px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-right: 0 !important;
    }

    .input-group-text {
        border-top-right-radius: 17px !important;
        border-bottom-right-radius: 17px !important;
        border-left: 0 !important;
    }
}

span {
    font-family: "Open Sans" !important;
    font-weight: 400 !important;
}

.btn {
    border-radius: 8px !important;
    @include Montserrat;
    font-weight: 400;
}

.breadcrumb-item + .breadcrumb-item {
    &::before {
        content: "→";
    }
}

.card {
    &.none {
        box-shadow: none !important;
        border: 0 !important;
        border-radius: 0 !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include Montserrat;
}

.dropdown-menu {
    border: 0;
    border-radius: 16px !important;
    @include shadow-lg;
}

.btn-primary {
    background-color: var(--bp-light-blue) !important;
    border-color: var(--bp-light-blue) !important;
}

.buy-tab-link,
.sell-tab-link {
    &.active {
        color: var(--bp-white) !important;
    }
}
