@tailwind base;
@tailwind components;
@tailwind utilities;

@import "bootstrap/scss/bootstrap";
@import "assets/scss/main.styles";

.link {
    // color: var(--bp-text-black) !important;
    color: var(--bp-text-blue) !important;
}

.font-link {
    font-family: 'Russo One', sans-serif;
  }

