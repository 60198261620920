.bp_login-cta {
    color: var(--bp-blue) !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-decoration: none !important;
}

.bp_signup-cta {
    background-color: var(--bp-light-blue) !important;
    background: var(--bp-light-blue) !important;
    color: var(--bp-white) !important;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    &:hover,
    &:active,
    &:focus {
        background: var(--bp-light-blue) !important;
        color: var(--bp-white) !important;
    }
}

.bp_mob-toggler {
    border: 0 !important;
    box-shadow: none !important;
}

.bp_header {
    .bp-mob-navbar,
    .bp_mob-nav-content,
    .bp_mob-nav-container {
        display: none;
    }

    .bp-desktop-navbar {
        display: block;
    }

    .bp_mob-nav-container {
        position: fixed !important;
        top: 0;
        bottom: 0;
        &.overlay {
            background-color: var(--bp-blue-overlay);
        }
        width: 100%;
        height: 100% !important;
        z-index: 1045;
        left: 0;
        right: auto;
        transition: left 0.5s;
        &.hide {
            left: -100% !important;
            right: auto !important;
        }
        .bp_mob-nav-content {
            position: absolute !important;
            top: 0;
            bottom: 0;
            background-color: var(--bp-white);
            width: calc(100% - 35%);
            height: 100% !important;

            &.navbar-expand {
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            .navbar-collapse {
                flex-basis: 100%;
                flex-grow: 1;
                align-items: flex-start;
                flex-direction: column;
            }

            .navbar-nav {
                flex-direction: column;
            }
        }
    }
}

.bp_intro {
    background-color: var(--bp-dark-blue) !important;
}

.bp_hero-header {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 84px;
    line-height: 99px;
    color: #ffffff;
    @media screen and (max-width: 768px) {
        font-size: 45px !important;
        line-height: initial !important;
    }
}

.bp_hero-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 48px;
    /* or 200% */

    color: #ffffff;
}

.bp_getstarted-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16.2259px 50.7059px;
    gap: 16px;

    height: 60.45px;
    left: 69px;
    top: 455px;
    width: 70% !important;

    @media screen and(max-width: 768px) {
        height: 50.45px;
        width: 72% !important;
        font-size: 18.8699px !important;
    }

    background: #007aff !important;
    border-radius: 17px;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 800;
    font-size: 27.8699px;
    line-height: 41px;

    text-transform: uppercase !important;

    &:active,
    &:focus,
    &:hover {
        @include shadow-sm;
    }
}

.bp_intro-section {
    @media screen and (min-width: 768px) {
        background: url("../../../assets/img/assets/bg-phone.png");
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background-position: right !important;
    }

    /* give space in the bottom */
    padding-bottom: 6rem !important;
}

.bp_best-seller-locator-section {
    background: url("../../../assets/img/assets/asset001.png") var(--bp-white-blue) no-repeat;
    background-size: contain !important;
    background-position: 100%;
    background-attachment: fixed;

    .bp_best-seller-locator {
        @include radius-17;
        .card-title {
            font-family: "Roboto";
            font-weight: 700;
        }

        transform: translateY(-93px);
    }

    .nav-link {
        border: 0px;
        @include font-roboto;
        color: var(--bp-black) !important;

        &.active {
            border-bottom-color: var(--bp-black) !important;
        }
        border-bottom: 2px solid transparent !important;
    }

    .tab-pane {
        margin-top: 1.5rem !important;
    }
}

.bp_get-offer-btn {
    @include font-roboto;
    font-size: 27px;
    width: 72% !important;
    display: block !important;
    margin: auto !important;

    @media screen and (max-width: 768px) {
        font-size: 22px !important;
        width: 72% !important;
        display: block !important;
        margin: auto !important;
    }
}

.bp_home-section-002 {
    background-color: var(--bp-white-blue) !important;

    .h-title {
        font-family: "Montserrat";
        font-weight: 700;
    }

    .card {
        border-radius: 5px !important;
        @include shadow-sm;

        .card-title {
            color: var(--bp-blue) !important;
            font-family: "Montserrat" !important;
            font-weight: 600 !important;
        }

        .card-text,
        p,
        span,
        small {
            font-family: "Montserrat" !important;
            font-weight: 400 !important;
        }
    }

    .bp_home-section-002-slider {
        display: none;

        .carousel-indicators {
            li {
                border-radius: 100% !important;
                padding: 5px !important;
                width: auto !important;
                height: auto !important;
                transition: all 0.5s;
                &.active {
                    background-color: var(--bp-black);
                }
            }
        }

        .carousel-item {
            .card {
                .card-text {
                    margin-bottom: 1.5rem !important;
                }
            }
        }
    }
}

.footer-dark {
    background-color: var(--bp-footer) !important;

    a {
        color: #efefee !important;
        text-decoration: none;
    }

    @media screen and (max-width: 768px) {
        .foot_logo-col {
            display: none !important;
        }

        .footer_hide-col-xlg {
            display: block !important;
            text-align: center !important;
        }
    }

    .footer_line {
        border-radius: 17px !important;
        background-color: var(--bp-white) !important;
        height: 3px !important;
    }

    @media screen and (min-width: 768px) {
        .footer_hide-col-xlg {
            display: none !important;
        }
    }
}

.support_coin {
    width: 70px !important;
}
