:root {
    --bp-blue: #171b8b;
    --bp-blue-overlay: #171b8b73;
    --bp-dark-blue: #1f1253;
    --bp-light-blue: #007aff;
    --bp-white: #ffffff;
    --bp-gold: #d87a0a;
    --bp-white-blue: #f5faff;
    --bp-black: #141313;
    --bp-selection: #cfcccc;
    --bp-gray-gold: #aaa412;
    --bp-footer: #243748;
    --bp-text-blue: #312ecb;
    --bp-text-bg-blue: #2f1efb;
    --bp-pinky: #c44582;
    --bp-gray: #f5f0f0;
    --bp-input-boder: #f5f5f5;
    --bp-line-border: #e1e0e0;
    --bp-green: #0da100;
    --bp-light: #d9d9d929;
    --bk-blue: #0a2b5b;
    --bk-placeholder: #a09a9a;
    --bk-success: #3ef62e;
    --bk-danger: #e71717;
    --bk-light-blue: #1b3adbed;
    --bk-bg: #1b3adb;
    --bk-gray: #c8bebe99;
    --circle-size: clamp(1.5rem, 5vw, 3rem);
    --spacing: clamp(0.25rem, 2vw, 0.5rem);
}
