.dash-nav-icon {
    font-size: 32px !important;
}

.icon-lg {
    font-size: large !important;
}

.profile-avatar-sec {
    position: relative !important;
    .profile-status-badge {
        position: absolute !important;
        bottom: 20px;
        left: 50% - 8;
        font-size: 16px !important;
    }
}

.trigger-profile-upl {
    @media screen and (max-width: 768px) {
        margin-top: 1.5rem !important;
    }
}
