@font-face {
  font-family: "RussoOne";
  src: local("RussoOne"),
    url("assets/fonts/RussoOneRegular.ttf") format("truetype");
  font-weight: bold;
}

.font-link {
  font-family: 'Russo One', sans-serif;
}

.openFont {
  font-family: 'Open Sans', sans-serif;
}

.openFont2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
}

.RussoOne {
  font-family: "RussoOne";
}

.forcegrey {
  color: gray !important;
}

.firstSectionBG {
  background-image: url("assets/img/firstSectionBG.png");
  /* height: 100%; */
  width: 100%;
}

/* .wrapreserve {
  flex-wrap: wrap-reverse;
} */

@media screen and (max-device-width:640px),
screen and (max-width:640px) {
  .wrapreserve {
    flex-wrap: wrap-reverse;
  }

  .hide_small {
    display: none;
  }
}