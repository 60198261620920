.input-overlay-label {
    padding-top: 25px !important;
    position: relative !important;

    &:hover {
        .form-label {
            transition: all 0.5s !important;
            top: 0 !important;
        }
    }
    .form-label {
        transition: all 0.5s !important;
        position: absolute !important;
        bottom: 0;
        top: auto;
        @include Montserrat;
        font-weight: 400 !important;

        &::after {
            content: " *";
            color: red !important;
        }
    }

    .form-control {
        border-radius: 0 !important;
        border: none !important;
        border-bottom: 1px solid var(--bp-selection) !important;
        box-shadow: none !important;
        &:hover,
        &:focus,
        &:valid {
            & ~ .form-label {
                transition: all 0.5s !important;
                top: 0 !important;
                color: initial !important;
            }
        }

        &:invalid {
            & ~ .form-label {
                color: maroon !important;
            }
        }
    }
}
