@mixin shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

@mixin shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

@mixin shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

@mixin shadow-none {
    box-shadow: none !important;
}

@mixin radius-17 {
    border-radius: 17px !important;
}

@mixin font-roboto {
    font-family: "Roboto";
    font-weight: 700;
}

@mixin Montserrat {
    font-family: "Montserrat" !important;
}

@mixin inter-bold {
    font-family: "Inter" !important;
    font-weight: 800 !important;
}

@mixin styledScrollbar($color: gray) {
    &::-webkit-scrollbar {
        width: 5px !important;
    }

    &::-webkit-scrollbar-track {
        background: #eceff0 !important; /* color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color !important; /* color of the scroll thumb */
        border-radius: 20px !important; /* roundness of the scroll thumb */
        border: 3px solid $color 1 !important; /* creates padding around scroll thumb */
        @include shadow-lg;
    }
}

.bk-btn-light-blue {
    background-color: var(--bk-light-blue) !important;
    border-color: var(--bk-light-blue) !important;
}
