.seller-object-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-radius: 50px;
    background-color: #bfd7e7;
    font-weight: 700;
    color: var(--bk-blue);
}

.mob-assets-object {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    padding: 10px !important;
    @include shadow-sm;
    border: 1px solid gray;
    border-radius: 8px !important;
    background-color: var(--bp-white) !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;

    .right {
        margin-right: 0.5rem !important;
    }
}

.assets-info-list {
    li {
        font-size: 12px !important;
    }
}

.coin-value-details {
    font-size: 12px !important;
}
