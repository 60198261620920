.bk__history-text,
.bk__history-icon {
    @media screen and (max-width: 768px) {
        font-size: 15px !important;
    }
}

table {
    @media screen and (max-width: 768px) {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 768px) {
    .bk__asset-img {
        width: 50px !important;
        height: 50px !important;
    }

    .bk__asset-text {
        font-size: 15px !important;
    }

    .rating-star {
        font-size: 22px !important;
    }

    .rating-star + .rating-star {
        margin-left: 0.2rem !important;
    }

    .bk__qa_0001-filter-input {
        margin-left: auto !important;
        width: 80% !important;
    }
}

.bk__mkp_action_btn {
    font-size: 14px !important;
}
.bk__news-container,
.bk__news-banner-container {
    position: relative !important;
}

.bk__news-banner-container {
    height: 45vh !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    background-blend-mode: overlay !important;

    @media screen and (max-width: 768px) {
        height: 15vh !important;
    }
}

.bk__mkn-img {
    display: block !important;
    margin: auto !important;
    width: 100% !important;
    height: 512px !important;
    @media screen and (max-width: 768px) {
        height: 200px !important;
    }
}

.bk_notif_new {
    background-color: rgba(135, 207, 235, 0.146);
}

.bk__chatroom-body {
    &:not(.fix-chatroom) {
        min-height: 100vh !important;
    }
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
    @include styledScrollbar;
}

.group-chatroom {
    .receiver,
    .sender {
        background-color: transparent !important;
    }
}

.bk__chat-room {
    .list-group-item {
        background-color: transparent;
    }
}

.bk__selfie-video-container {
    video {
        border-radius: 20px !important;
        @media screen and (max-width: 768px) {
            display: block !important;
            margin: auto !important;
            width: 200px !important;
            height: 200px !important;
        }
    }
}

.webcam-controls {
    width: 35%;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    @media screen and (max-width: 768px) {
        button {
            font-size: 10px !important;
        }
        width: 85% !important;
    }
}

.actions {
    @media screen and (max-width: 768px) {
        display: flex !important;
        flex-direction: column !important;
        &.hide-sm-screen {
            display: none !important;
        }
    }

    @media screen and (min-width: 769px) {
        &.show-sm-screen {
            display: none !important;
        }
    }
}

.bk__supported-coin-group {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    span {
        font-weight: 700 !important;
    }

    img {
        @media screen and (max-width: 768px) {
            width: 50px !important;
        }
    }
}

// ------------> [12/01/2023]

a.text-light {
    color: var(--bp-white) !important;
}
