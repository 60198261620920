.bp_faq-section-002 {
    .accordion-button {
        background-color: var(--bp-footer) !important;
        color: var(--bp-white) !important;
        @include Montserrat;
        font-weight: 400 !important;
    }

    .accordion-button::after {
        background-color: var(--bp-white) !important;
        border-radius: 3px !important;
    }
}
