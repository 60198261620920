.chat-messages {
    &:not(.group-room) {
        .list-group-item {
            border: 0 !important;
            &.sender {
                margin-left: auto !important;
                margin-right: 0 !important;
                text-align: justify !important;
                background-color: var(--bp-text-blue);
                color: var(--bp-white);
                border-radius: 17px;
                border-bottom-right-radius: 0 !important;
            }

            &.receiver {
                margin-right: auto !important;
                margin-left: 0 !important;
                text-align: start !important;

                .user-avatar {
                    border-radius: 100%;
                    margin-right: 0.5rem;
                }
                .message-object {
                    padding: 10px;
                    background-color: var(--bp-white-blue);
                    border-radius: 17px;
                    border-bottom-left-radius: 0 !important;
                }
            }
        }

        .sender + .sender {
            margin-top: 0.3rem !important;
            border-radius: 17px !important;
        }

        .receiver + .receiver {
            padding-top: 0 !important;
            .user-avatar {
                visibility: hidden !important;
            }

            .message-object {
                border-radius: 17px !important;
            }
        }

        .sender + .receiver {
            margin-top: 0.3rem !important;
        }

        .receiver + .sender {
            margin-top: 0.5rem !important;
        }
    }

    .user-avatar {
        position: relative !important;
        top: 25px;
        width: 32px !important;
        height: 32px !important;
    }
}

.chat-messages {
    &.group-room {
        .list-group-item {
            border: 0 !important;
            &.sender {
                margin-left: auto !important;
                margin-right: 0 !important;

                .user-avatar {
                    border-radius: 100%;
                    margin-left: 0.5rem;
                }
                .message-object {
                    padding: 10px;
                    text-align: justify !important;
                    background-color: var(--bp-text-blue);
                    color: var(--bp-white);
                    border-radius: 17px;
                    border-bottom-right-radius: 0 !important;
                }
            }

            &.receiver {
                margin-right: auto !important;
                margin-left: 0 !important;
                text-align: start !important;

                .user-avatar {
                    border-radius: 100%;
                    margin-right: 0.5rem;
                }
                .message-object {
                    padding: 10px;
                    background-color: var(--bp-white-blue);
                    border-radius: 17px;
                    border-bottom-left-radius: 0 !important;
                }
            }
        }

        .sender + .sender {
            .user-avatar {
                visibility: visible !important;
            }

            .message-object {
                border-radius: 17px !important;
            }
        }

        .receiver + .receiver {
            .user-avatar {
                visibility: visible !important;
            }

            .message-object {
                border-radius: 17px !important;
            }
        }

        .sender + .receiver {
            margin-top: 0.5rem !important;
        }

        .receiver + .sender {
            margin-top: 0.5rem !important;
        }
    }
}

.chatroom-message-composer {
    .attach-icon {
        border-radius: 0 !important;
        border: 0 !important;
        background-color: transparent !important;
        svg,
        .icon,
        .fa,
        .far,
        .fab {
            font-size: 50px;
            @media screen and (max-width: 768px) {
                font-size: 25px !important;
            }
            padding: 4px;
            border-radius: 100%;
            cursor: pointer !important;
            &:hover {
                @include shadow-sm;
                background-color: #eee;
            }
        }
    }

    .input-area {
        border-radius: 17px !important;
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .emoji-icon {
        padding: 5px !important;
        font-size: 32px;
        @media screen and (max-width: 768px) {
            font-size: 20px !important;
        }
        box-shadow: none !important;
        background-color: transparent !important;
        .fa-smile {
            color: var(--bp-gold);
        }
    }

    .send-btn {
        border-radius: 100% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        .icon {
            font-size: 32px !important;
            @media screen and (max-width: 768px) {
                font-size: 16px !important;
            }
        }
    }
}
