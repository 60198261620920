#bk__dash-main-content-area {
    min-height: 100vh !important;
    display: flex !important;
    flex-direction: column !important;
    footer {
        margin-top: auto !important;
    }
}
.dash-push-notification {
    .nav-link {
        font-size: 22px;
        position: relative !important;

        .push-notification-ping {
            display: block;
            position: absolute !important;
            padding: 3px;
            top: 10px;
            right: 18px;
            font-size: 9px !important;
            text-align: center;

            &:empty {
                display: block;
                position: absolute !important;
                padding: 5px !important;
                top: 15px !important;
                right: 18px !important;
            }
        }
    }
}

.dash-sidebar-col {
    border-right: 2px solid var(--bp-line-border) !important;
    background-color: var(--bp-white) !important;

    .dash-sidenav-wrapper {
        min-height: 75vh;

        .nav {
            border: none !important;
            .nav-item,
            .nav-link {
                border-radius: 0 !important;
                border: 0 !important;
                color: var(--bp-black);
                margin-left: 0.5rem !important;
                @include Montserrat;
                margin-right: 0.5rem !important;
            }
            .nav-item {
                padding-block: 10px !important;
                transition: all 0.5s !important;
                &.active,
                &:hover {
                    background-color: #eee;
                    border-top-right-radius: 100px !important;
                    border-bottom-right-radius: 100px !important;
                }
            }

            .nav-item + .nav-item {
                margin-top: 0.5rem !important;
            }
        }
    }
}

.dash-tabs-main {
    .buy-tab-link {
        @include Montserrat;
        font-weight: 700;

        &.active {
            background-color: var(--bp-text-blue) !important;
        }
    }

    .sell-tab-link {
        @include Montserrat;
        font-weight: 700;

        &.active {
            background-color: var(--bp-light-blue) !important;
        }
    }

    .buy-tab-content {
        .sub-tabs-items {
            .nav-link {
                border-radius: 10px;
                background-color: var(--bp-gray) !important;
                color: var(--bp-black) !important;
                &.active {
                    color: var(--bp-white) !important;
                    background-color: var(--bp-text-blue) !important;
                    border-bottom: 3px solid var(--bp-text-blue) !important;
                }
            }

            .nav-item {
                margin: 0.5rem !important;
            }
        }
    }

    .sell-tab-content {
        .sub-tabs-items {
            .nav-link {
                border-radius: 10px;
                background-color: var(--bp-gray) !important;
                color: var(--bp-black) !important;
                &.active {
                    color: var(--bp-white) !important;
                    background-color: var(--bp-light-blue) !important;
                    border-bottom: 3px solid var(--bp-light-blue) !important;
                }
            }

            .nav-item {
                margin: 0.5rem !important;
            }
        }
    }
}

.buy-btn {
    background-color: var(--bp-text-blue) !important;
    border-color: var(--bp-text-blue) !important;
}
.sell-btn {
    background-color: var(--bp-light-blue) !important;
    border-color: var(--bp-light-blue) !important;
}

.content-row {
    position: relative !important;
    .card {
        cursor: pointer;
        &:hover {
            & ~ .dash-card-opts {
                visibility: visible !important;
            }
        }
    }

    .dash-card-opts {
        transition: all 0.5s !important;
        position: absolute !important;
        width: 99%;
        z-index: 100;
        top: auto;
        bottom: 0px;
        left: 0 !important;
        right: 0 !important;
        visibility: hidden;

        &:hover {
            visibility: visible !important;
        }

        .list-group-item {
            border: 0 !important;
            border-radius: 0 !important;
            background-color: var(--bp-blue);

            width: 100%;
            color: var(--bp-white);
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;

            &:hover {
                background-color: #1c4e98 !important;
            }
            &.sell {
                background-color: var(--bp-light-blue);
            }

            height: 50px;

            span {
                font-size: 16px;
                font-weight: 700 !important;
                color: var(--bp-white) !important;
            }
            &:first-child {
                border-right: 1px solid #ddd !important;
            }
        }
    }
}

.bk__userProfileAvatar {
    width: 150px !important;
    height: 150px !important;
    border-radius: 100% !important;
}
.bk__nav-profile-avatar {
    width: 36px !important;
    height: 36px !important;

    border-radius: 100% !important;
}

.bk__hide-on-mobile {
    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.bk__custom_dropdown {
    position: relative !important;
}
.bk__dropdown_menu {
    transition: all 0.5s !important;
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    top: 55px !important;

    width: 100%;
    display: none;
    background-color: var(--bp-white) !important;
    border-radius: 15px;
    &.show {
        display: block !important;
        @include shadow-lg;
        z-index: 1000;
    }
    padding: 10px;
    min-height: fit-content !important;
    max-height: 50vh;
    overflow: hidden !important;
    overflow-y: scroll !important;
    &.search-menu {
        top: 0px !important;
        max-height: 100vh;
    }
    @include styledScrollbar;
}

.bk__dropdown_value {
    padding: 12px !important;
    background-color: var(--bp-light) !important;
    border-radius: 10px !important;
    @include shadow-sm;
    border: 1px solid #eee;
    cursor: pointer !important;
    width: 100% !important;
}

.bk__selected_value {
    font-weight: 600 !important;
}

.bk__tab-items {
    padding: 10px;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    display: flex !important;
    gap: 5px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    width: 100% !important;
    @include styledScrollbar(rgba(128, 128, 128, 0.232));

    @media screen and (max-wdith: 768px) {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .bk__tab-item {
        background-color: var(--bp-gray) !important;
        border-color: var(--bp-gray) !important;
        border-radius: 10px !important;
        &:hover,
        &.active {
            &.buy-tab-item {
                background-color: var(--bp-text-blue) !important;
                border-color: var(--bp-text-blue) !important;
                color: var(--bp-white) !important;
            }

            &.sell-tab-item {
                background-color: var(--bp-light-blue) !important;
                border-color: var(--bp-light-blue) !important;
                color: var(--bp-white) !important;
            }
        }
    }

    .bk__tab-item + .bk__tab-item {
        margin-left: 0.5rem !important;
    }
}

.dash-mobile-content {
    position: relative !important;
}
