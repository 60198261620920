.text-dark-blue {
    color: var(--bp-dark-blue) !important;
}

.text-light-blue {
    color: var(--bp-light-blue) !important;
}

.text-gold {
    color: var(--bp-gold) !important;
}

.bg-none {
    background-color: inherit !important;
}

.btn-blue,
.bg-blue {
    background-color: var(--bp-light-blue) !important;
    &.btn {
        border-color: var(--bp-light-blue) !important;
    }
}

.navbar-close-icon {
    background-image: url("../../../assets/img/assets/close-icon.svg");

    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.community-text {
    font-family: "Montserrat" !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 18px;
}

.font-montserrat {
    font-family: "Montserrat" !important;
}
.font-opensane {
    font-family: "Open Sans";
}

.fs-41 {
    font-size: 41.3982px !important;
}
.font-wdth-700 {
    font-weight: 700 !important;
}

.bg-gray {
    background-color: var(--bp-white-blue) !important;
}

.text-gray-gold {
    color: var(--bp-gray-gold) !important;
}

.card-bordered {
    border-radius: 16px !important;
}

.card-shadow {
    @include shadow;
}

.text-blue {
    color: var(--bp-text-blue) !important;
}
.bg-blue {
    background-color: var(--bp-text-blue) !important;
}

.bg-light-blue {
    background-color: var(--bp-white-blue) !important;
}

.input-label {
    @include Montserrat;
    font-weight: 400 !important;
}

.bg-outline {
    background-color: var(--bp-white-blue) !important;
    border-color: var(--bp-light-blue) !important;
    color: var(--bp-dark-blue);
    box-shadow: none !important;
    &:active,
    &:hover {
        color: var(--bp-dark-blue);
    }
}

.h-pinky,
.text-pinky {
    color: var(--bp-pinky) !important;
}

.p-bold {
    @include inter-bold;
}

.bg-tick-gray {
    background-color: var(--bp-gray) !important;
}

.section-header {
    text-shadow: 2px 2px 10px rgb(6, 6, 6);
}

.navigate-back-icon {
    padding: 8px;
    border-radius: 100%;
    color: var(--bp-black);
    @include shadow-sm;
    &:active,
    &:hover,
    &:visited,
    &:focus {
        background-color: var(--bp-white-blue);
    }
}

.bk-text-light {
    color: var(--bp-light) !important;
}
.bk-bg-light {
    background-color: var(--bp-light) !important;
}

.btn {
    @include shadow-sm;

    &.bk-navy-blue {
        background-color: var(--bk-blue) !important;
        border-color: var(--bk-blue) !important;
    }
}

.bk-text-placeholder {
    color: var(--bk-placeholder) !important;
    font-weight: 600 !important;
}

.pointer {
    cursor: pointer !important;
}

.bk-text-success {
    color: var(--bk-success) !important;
    font-weight: 600;
}

.bk-text-danger {
    color: var(--bk-danger) !important;
    font-weight: 600;
}

.form-input-group {
    position: relative !important;
    .overlap-text {
        position: absolute !important;
        bottom: 0;
        right: 0;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding-right: 5px;
        width: 50px;
    }

    .form-control {
        padding-right: 52px;
    }
}

.sell-tab,
.buy-tab {
    background-color: var(--bk-gray) !important;
    color: var(--bp-black) !important;
}
.sell-tab {
    &.active {
        background-color: var(--bp-light-blue) !important;
        color: var(--bp-white) !important;
        border-radius: 0 !important;
    }
}
.buy-tab {
    &.active {
        background-color: var(--bp-text-blue) !important;
        color: var(--bp-white) !important;
        border-radius: 0 !important;
    }
}

.refresh-icon {
    font-size: 32px;
}

.rating-star {
    font-size: 36px;
    &.stared {
        color: gold !important;
    }

    color: rgb(194, 199, 203);
}

.rating-star + .rating-star {
    margin-left: 1.5rem;
}

.copy-icon {
    font-size: x-large !important;
    color: rgb(155, 155, 155);
    cursor: pointer;
}

.w-fit {
    width: fit-content !important;
}

.name-icon {
    border-radius: 50%;
    padding: 8px;
    padding-right: 15px;
    padding-left: 15px;
    background-color: var(--bk-blue) !important;
    color: var(--bp-white);
}
.mw-75 {
    min-width: 75% !important;
}

.copy-link-input-group {
    .btn {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.img-icon-md {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100% !important;
}
