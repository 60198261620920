.bk-onboarding-overlay {
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    overflow: hidden;
    overflow-y: scroll !important;
    width: 100%;
    height: 100%;
    padding: 10px;
    @include styledScrollbar;
    background-color: var(--bp-white) !important;
    &.content-center {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .btn-close {
        margin: 0.5rem;
        font-size: 22px;
        border-radius: 100%;
        padding: 15px;
        background-color: #efef;
        @include shadow-sm;
    }

    form {
        @media screen and (min-width: 768px) {
            width: 75% !important;
        }
    }
}

.bk-onboarding-stepper {
    display: flex;
}

.stepper__item {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;

    &:before {
        --size: 3rem;
        content: attr(data-step);
        color: #e0e0e0;
        justify-content: center;
        align-items: center;
        display: flex;
        width: var(--circle-size);
        height: var(--circle-size);
        border-radius: 50%;
        background-color: var(--bk-bg);
        opacity: 0.5;
        margin: 0 auto 1rem;
    }

    &:not(:last-child) {
        &:after {
            content: "";
            position: relative;
            top: calc(var(--circle-size) / 2);
            width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
            left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
            height: 2px;
            background-color: #e0e0e0;
            order: -1;
        }
    }

    &.completed {
        &::before {
            opacity: 1 !important;
        }
        &:not(:last-child) {
            &:after {
                background-color: var(--bk-bg) !important;
            }
        }
    }
}

.stepper__title {
    font-weight: bold;
    font-size: clamp(1rem, 4vw, 1.25rem);
    margin-bottom: 0.5rem;
}

.stepper__desc {
    color: grey;
    font-size: clamp(0.85rem, 2vw, 0.3rem);
    padding-left: var(--spacing);
    padding-right: var(--spacing);
}

sup.text-danger {
    font-size: 16px !important;
    font-weight: 700;
}

.bk__suggested_username {
    padding: 10px;
    font-size: 16px !important;
    cursor: pointer;
    border-radius: 17px !important;
    @include shadow-sm;
    background-color: #eee;
    transition: background-color 0.5s;
    &:hover,
    &.active {
        background-color: var(--bp-text-blue) !important;
        color: var(--bp-white);
    }
}

.bk__suggested_username + .bk__suggested_username {
    margin-left: 0.5rem !important;
}
